import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import Header4 from "../components/contents/header4"
import Content1 from "../content/business_opportunities/bo_content1"

const BsOppPage = ({ data, location }) => (
  <Layout
    location={location}
    header={
      <Header4
        src_sfondo={data.file.childImageSharp.fluid}
        title={data.site.siteMetadata.business_opportunities.title}
        link_page={data.site.siteMetadata.business_opportunities.link_page}
      />
    }
  >
    <SEO
      title={data.site.siteMetadata.business_opportunities.title}
      pathname={data.site.siteMetadata.business_opportunities.link_page}
    />

    <div id="business-opportunities1">
      <Content1 />
    </div>
  </Layout>
)

export const query = graphql`
  {
    file(relativePath: { regex: "/business-opportunities/header/" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        business_opportunities {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default BsOppPage
