import React from "react"
/* import { Link } from "gatsby" */

import ItemPdf from "./itemPdf"
import ItemPng from "./itemPng"
import { Row, Col /*, Container, CardDeck, Card */ } from "react-bootstrap"
import ModalObject from "../../components/contents/modalObject"

const scheda = ({ nodo_reparto, className }) => {
  return (
    <div className="">
      <Row className={`justify-content-md-center ${className}`}>

        <CardMode1 nodes={nodo_reparto} />
        
      </Row>
      {/* <Row>
        <Col>
          <CardMode2 nodes={nodo_reparto} />
        </Col>
      </Row> */}
    </div>
  )
}

const CardMode1 = ({ nodes }) => (
  <>
    {nodes.map(({ frontmatter, html }, index) => (
      <Col key={index} className="p-4" xs={12} sm={12} md={6} lg={6} xl={3}>
        <div className="border shadow p-4 h-100">
          <h6 className="text-center">
            {index + 1}) {frontmatter.title} <br />
            <small className="text-muted">{frontmatter.type}</small>
          </h6>
          <ModalObject
            childLink={
              <ItemPng
                name={frontmatter.file_pdf}
                valore_alternativo={frontmatter.file_pdf}
              />
            }
            modalTitle={frontmatter.title}
            className="border border-dark rounded p-1"
          >
            <div>
              <h5>
                {index + 1}) {frontmatter.title} <br />
                <small className="text-muted">{frontmatter.type}</small>
              </h5>
              <div
                className="mt-4 blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>

            {/* <Link to={`/${frontmatter.path}`} className="link-no-style">
              CONTINUA
            </Link> */}
          </ModalObject>
          {/* <ItemPng
                  name={frontmatter.file_pdf}
                  valore_alternativo={frontmatter.file_pdf}
                /> */}
          {/* <div
            className="d-flex align-items-end bd-highlight"
            style={{
              height: `5rem`,
            }}
          >
            <div>
              <ItemPdf
                className="mt-2"
                name={frontmatter.file_pdf}
                valore_alternativo={frontmatter.file_pdf}
              />
            </div>
          </div> */}
          <div className="row align-items-end">
            <div className="col">
              <div className=" h-100 d-inline-block">
                <ItemPdf
                  className="mt-2"
                  name={frontmatter.file_pdf}
                  valore_alternativo={frontmatter.file_pdf}
                />
              </div>
            </div>
          </div>
        </div>
      </Col>
    ))}
  </>
)
/* 
const CardMode2 = ({ nodes }) => (
  <CardDeck>
    {nodes.map(({ frontmatter, html }, index) => (
      <Card key={index}>
        <ModalObject
          childLink={
            <ItemPng
              className="p-1"
              name={frontmatter.file_pdf}
              valore_alternativo={frontmatter.file_pdf}
            />
          }
          modalTitle={frontmatter.title}
        >
          <div>
            <h5>
              {index + 1}) {frontmatter.title} <br />
              <small className="text-muted">{frontmatter.type}</small>
            </h5>
            <div
              className="mt-4 blog-post-content"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </div>

          <Link to={`/${frontmatter.path}`} className="link-no-style">
            CONTINUA
          </Link>
        </ModalObject>
        
        <Card.Body>
          <Card.Title>
            {index + 1}) {frontmatter.title}
          </Card.Title>
          <Card.Text>
            <ItemPdf
              className="mt-2"
              name={frontmatter.file_pdf}
              valore_alternativo={frontmatter.file_pdf}
            />
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted">Last updated 3 mins ago</small>
        </Card.Footer>
      </Card>
    ))}
  </CardDeck>
) */

export default scheda
