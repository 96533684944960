import React from "react"
import { StaticQuery, graphql } from "gatsby"

import { Container, Col, Row /* , Card */ } from "react-bootstrap"

import ListaSchede from "./listaSchede"

import { Link } from "gatsby"

/* 
title: Programma MOTERG BIO
date: 2020-03-09
path: moterg-bio
sort: 1
reparto: BusinessOpportunities
file_pdf: motergbio.pdf
*/
const bOContent = () => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/md/brochure/" } }
          sort: { order: ASC, fields: frontmatter___sort }
        ) {
          group(field: frontmatter___reparto) {
            totalCount
            fieldValue
            nodes {
              excerpt(format: MARKDOWN, pruneLength: 400)
              html
              frontmatter {
                title
                sort
                path
                reparto
                file_pdf
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Container>
          <Row>
            <Col md={1}></Col>
            <Col className="pt-5">
              <div>
                <p className="paragraph2">
                  Coerentemente con gli obiettivi del nostro piano industriale e
                  in linea con i principi fondanti dell’esposizione universale
                  EXPO Milano 2015, in tema di Tecnologie innovative per
                  “Nutrire il Pianeta” e produrre “ Energia per la Vita”, con
                  entusiasmo e certi di un sicuro interesse dei maggiori player
                  di settore, impegnati come siamo per assicurare crescita,
                  sviluppo economico e occupazionale al nostro territorio e
                  contestualmente una valorizzare del know how aziendale e dei
                  brevetti in nostro possesso, riteniamo strategicamente
                  importante selezionare un partner di primario standing per la
                  realizzazione di alcuni dei nostri programmi di investimento
                  in corso. Siamo convinti che qualsiasi crisi economica si
                  possa vincere favorendo e promuovendo investimenti altamente
                  performanti, quale occasione da non perdere per il rilancio
                  del Made in Italy di quella la creatività, del design e della
                  spiccata volontà di “fare”, tipica dell’imprenditoria italiana
                  Ricerca, Sviluppo ed Innovazione Tecnologica sono gli
                  ingredienti indispensabili per promuovere programmi di
                  investimento in linea con i piani di sviluppo aziendale, di
                  investitori seri e qualificati, che puntano sull’economia
                  reale invece che sulla mera speculazione finanziaria.
                  Investire, oggi, significa orientare le proprie scelte
                  manageriali verso iniziative concrete che assicurino un
                  ritorno economico certo ed in un tempo accettabile,
                  individuando, pesando e contenendo i rischi e sfruttando le
                  potenzialità di business. Oggi il nostro principale interesse
                  è, dunque, ricercare un partner di progetto o un partner
                  strutturato che investa insieme a noi e che dia slancio alla
                  crescita aziendale del nostro Gruppo. Tale soggetto potrà
                  indirettamente scegliere di investire in uno o più progetti
                  intestati a singole SPVs (special purpose vehicle society) o
                  acquisire direttamente partecipazioni della holding Archimede
                  Knowledge Industries.
                </p>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row>
            <Col md={1}></Col>
            <Col className="">
              <div>
                <p className="paragraph2">
                  A tal proposito, riportiamo di seguito una sintesi di alcuni
                  dei programmi di investimento del nostro gruppo, ponendo alla
                  vostra attenzione sui punti di forza, la valenza commerciale e
                  il potenziale industriale/imprenditoriale dei nostri programmi
                  di investimento IN ITALIA:
                </p>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>

        <Container
          fluid
          className="h-100"
          style={{
            paddingTop: `2rem`,
            paddingBottom: `2rem`,
          }}
        >
          <Row>
            <Col md={1}></Col>
            <Col>
              <div>
                <div className="display-4 text-center">Divisioni :</div>
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
          <Row className="p-3">
            <Col></Col>
            <Col>
              <hr />
            </Col>
            <Col></Col>
          </Row>

          <ListaSchede lista={data.allMarkdownRemark.group} />

          <Row>
            <Col></Col>
            <Col></Col>
            <Col>
              <Link
                className="my-2 btn btn-primary mx-3"
                to="/archimede-research"
              >
                Scopri i nostri sviluppi
              </Link>
            </Col>
          </Row>

          {/* {data.allMarkdownRemark.edges.map(({ node }, index) => (
            <div key={index}>
              <Row>
                <Col md={1}></Col>
                <Col>
                  <div>
                    <h3>{node.frontmatter.title}</h3>
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: node.html }}></div>
                </Col>
                <Col md={1}></Col>
              </Row>
            </div>
          ))} */}

          {/* <div class="col-md-12 text-right">
							<img class="img-fluid w-50 mt-2" src="assets/images/loghi/firma.png" alt="">
						</div> */}
        </Container>
      </>
    )}
  />
)
export default bOContent
