import React from "react"
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import ConvertLink from "../../components/htmls/ConvertLink"

function trovaItem(elements, name, value) {
  let val = elements.filter(({ node }) => {
    return node.relativePath.includes(name)
  })
  if (val.length > 0) {
    return (
      <a
        className="font-weight-light"
        target="_blank"
        href={val[0].node.publicURL}
        rel="noopener noreferrer"
      >
        SCARICA LA BROCHURE
      </a>
    )
  } else {
    return <ConvertLink path={value}>SCARICA LA BROCHURE</ConvertLink>
  }
}

const ItemLink = ({ name, valore_alternativo, className }) => (
  <StaticQuery
    query={graphql`
      {
        allFile(filter: { absolutePath: { regex: "/files/brochure/" } }) {
          edges {
            node {
              name
              publicURL
              relativePath
            }
          }
        }
      }
    `}
    render={data => (
      <div className={className}>{trovaItem(data.allFile.edges, name, valore_alternativo)}</div>
    )}
  />
)

ItemLink.propTypes = {
  name: PropTypes.string,
  valore_alternativo: PropTypes.string,
}

ItemLink.defaultProps = {
  name: "",
  valore_alternativo: "",
}

export default ItemLink
